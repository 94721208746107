export const infoQuestions = [
	{
		question:
			'¿En que se diferencian las toallas higiénicas y los protectores de TENA Discreet con las toallas y los protectores tradicionales?',
		consultationId: 1,
		answer: `
        Los protectores y toallas higiénicas de TENA Discreet son especializados,
        pensados en los cambios que sufrimos las mujeres con el paso de los años,
        además tienen una tecnología especializada que les permite absorber fluidos
        como la orina sin deshacerse y no dejan escapar olores.
        `,
	},
	{
		question:
			'¿Por qué no debe usar una toalla higiénica tradicional si tengo goteos de orina?',
		consultationId: 2,
		answer: `
        Las toallas higiénicas y los protectores tradicionales están diseñados 
        para fluidos asociados al período (flujo y sangre) por eso al usarlos y tener 
        goteos de orina puedes sufrir escapes, irritaciones o que el producto se deshaga.
        `,
	},
	{
		question: '¿Por qué tengo goteos de orina durante el embarazo?',
		consultationId: 3,
		answer: `
        Cuando el bebé comienza a crecer y a acomodarse dentro del vientre desplaza 
        los órganos causan presión sobre la vejiga y esto es lo que causa eso goteos 
        o escapes de orina.
        `,
	},
	{
		question: '¿Qué puedo hacer para evitar la incontinencia?',
		consultationId: 4,
		answer: `
        Uno de los principales aliados contra la incontinencia son los Ejercicios de Kegel 
        que fortalecen el piso pélvico.
        `,
	},
	{
		question: '¿Qué cambios sufre mi zona íntima con los años?',
		consultationId: 5,
		answer: `
        Con los años nuestra zona íntima cambia debido al embarazo y a la menopausia, 
        cambiando su PH y requieriendo cuidados especiales.
        `,
	},
]
