import React from 'react'
import DescreetContent from '../components/organisms/descreetContent/descreetContent'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useMobileMode from '../shared/hooks/useMobileMode'

const Descreet = props => {
	const { isMobile, currentPage } = useMobileMode()
	const dataQuery = props.pageContext.data.allContentfulTenaDiscreet.nodes[0]
	const leafletContent =
		props.pageContext.data.allContentfulTenaRegistroV2.nodes[0]

	return (
		<Layout
			isMobile={isMobile}
			currentPage={currentPage}
			isDescreet={true}
			btnWhatsapp={true}
		>
			<SEO
				title={dataQuery.metaTitulo}
				description={dataQuery.metaDescripcion.metaDescripcion}
				image={dataQuery?.bannerPrincipal[0]?.bannerDesktop?.file?.url}
				slug='/discreet/'
				lang={props.pageContext.language}
			/>
			<DescreetContent dataQuery={dataQuery} leafletContent={leafletContent} />
		</Layout>
	)
}

export default Descreet
