import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import DianaVahosMorada from '../../../assets/images/diana-vahos-morado.png'
import IconAnonimo from '../../../assets/images/icono-anonimo-discreet.svg'
import RichText from '../../../shared/components/atoms/rich-text/rich-text'
import CardArticleItem from '../../../shared/components/molecules/cardArticleItem/cardArticleItem'
import CardProduct from '../../../shared/components/molecules/cardProduct/cardProduct'
import ModalDetailComment from '../../../shared/components/molecules/modal-detail-comment/modal-detail-comment'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import useMobileMode from '../../../shared/hooks/useMobileMode'
import ArticleService from '../../../shared/services/articles'
import ProductService from '../../../shared/services/products'
import SessionService from '../../../shared/services/session'
import BannerDescreet from '../../atoms/banner-descreet/banner-descreet'
import LeafletCard from './../../../shared/components/molecules/leaflet-card/leaflet-card'
import ModalRetails from './../../../shared/components/molecules/modal-retails/modal-retails'
import './descreetContent.scss'
import { infoQuestions } from './infoQuestions'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'

const DescreetContent = props => {
	const { dataQuery: pageInfo, leafletContent } = props
	const { isMobile } = useMobileMode()
	const { homeLink } = useHomeLinkMode()

	const [listLikes, setlistLikes] = useState(null)
	const [localLikes, setlocalLikes] = useState([])
	const [listArticlesLikes, setlistArticlesLikes] = useState(null)
	const [localArticlesLikes, setlocalArticlesLikes] = useState([])
	const [isModal, setisModal] = useState(false)
	const [listQuestions] = useState(infoQuestions)
	const [question, setQuestion] = useState(null)
	const [indexModal, setIndexModal] = useState(null)
	const [modalShop, setModalShop] = useState(false)

	// Show modal
	const handleShowModal = index => {
		setIndexModal(index)
		// Verify if have more comments to show
		if (index > listQuestions.length - 1) {
			return false
		}

		const body = document.getElementsByTagName('body')[0]
		body.classList.add('overflow-body-hidden')

		setQuestion(listQuestions[index])
		setisModal(true)
	}

	// Articles likes
	const handleLoadArticleLikes = () => {
		if (
			!(
				localStorage.getItem('tokenData') || sessionStorage.getItem('tokenData')
			)
		) {
			// Verify if is user logged
			ArticleService.getAnomLikes()
				.then(res => {
					setlistArticlesLikes(res.data)
				})
				.catch(e => console.log(e))
		} else {
			localStorage.removeItem('LOCAL_LIKES_ARTICLES')

			const config = {
				headers: {
					Authorization: SessionService.getIdTokenData(),
				},
			}

			ArticleService.getAuthLikes(config)
				.then(res => {
					for (let index = 0; index < res.data.length; index++) {
						if (res.data[index].userLike) {
							if (localStorage.getItem('LOCAL_LIKES_ARTICLES') !== null) {
								const copyLocalLikes = JSON.parse(
									localStorage.getItem('LOCAL_LIKES_ARTICLES')
								)
								localStorage.setItem(
									'LOCAL_LIKES_ARTICLES',
									JSON.stringify(copyLocalLikes.concat(res.data[index].idCMS))
								)
								setlocalArticlesLikes(
									copyLocalLikes.concat(res.data[index].idCMS)
								)
							} else {
								localStorage.setItem(
									'LOCAL_LIKES_ARTICLES',
									JSON.stringify([res.data[index].idCMS])
								)
								setlocalArticlesLikes([res.data[index].idCMS])
							}
						}
					}
					setlistArticlesLikes(res.data)
				})
				.catch(e => console.log(e))
		}
	}

	// products likes
	const handleLoadLikes = () => {
		if (
			!(
				localStorage.getItem('tokenData') || sessionStorage.getItem('tokenData')
			)
		) {
			// Verify if is user logged
			ProductService.getAnomLikes()
				.then(res => {
					setlistLikes(res.data)
				})
				.catch(e => console.log(e))
		} else {
			localStorage.removeItem('LOCAL_LIKES_PRODUCTS')
			const config = {
				headers: {
					Authorization: SessionService.getIdTokenData(),
				},
			}

			ProductService.getAuthLikes(config)
				.then(res => {
					for (let index = 0; index < res.data.length; index++) {
						if (res.data[index].userLike) {
							if (localStorage.getItem('LOCAL_LIKES_PRODUCTS') !== null) {
								const copyLocalLikes = JSON.parse(
									localStorage.getItem('LOCAL_LIKES_PRODUCTS')
								)
								localStorage.setItem(
									'LOCAL_LIKES_PRODUCTS',
									JSON.stringify(copyLocalLikes.concat(res.data[index].idCMS))
								)
								setlocalLikes(copyLocalLikes.concat(res.data[index].idCMS))
							} else {
								localStorage.setItem(
									'LOCAL_LIKES_PRODUCTS',
									JSON.stringify([res.data[index].idCMS])
								)
								setlocalLikes([res.data[index].idCMS])
							}
						}
					}
					setlistLikes(res.data)
				})
				.catch(e => console.log(e))
		}
	}

	const isLiked = (idProduct, list) => {
		let i
		for (i = 0; i < list.length; i++) {
			if (list[i] === idProduct) {
				return true
			}
		}

		return false
	}

	const getProductAtr = (idProduct, list) => {
		let i
		for (i = 0; i < list.length; i++) {
			if (list[i].idCMS === idProduct) {
				return list[i]
			}
		}

		return false
	}

	const userInfo=dataLayerUserInfo()

	const listProducts = pageInfo?.productosDiscreet.map((producto, index) => {
		producto.imagenProducto = producto.imagenProductoPrevisualizacion
		const params=(prod, index2)=>{			
			const par={
				event:'select_item',
				params:{	
					items: [{
						item_id: prod.codigoDeProducto,
						item_name: prod.nombreProducto,
						discount: 0,
						index: index2,
						item_list_name: prod.categorias[0].titulo,
						affiliation: 'Tena Online',
						item_category: prod.categorias[0].titulo,
						item_brand: 'Tena',
						item_variant: prod.linea? prod.linea[0].nombreDeEtiqueta : prod.categorias[0].titulo,
						price: 0,
                        currency: process.env.LOCALE,
                        quantity: 1,
						
					}],
					item_list_name: prod.linea? prod.linea[0]?.nombreDeEtiqueta : undefined,
					item_list_id: prod.id,
					drip_degree: prod.tipoDeIncontinencia? prod.tipoDeIncontinencia[0].nombreDeEtiqueta: '',
					user_data: userInfo.user_data? userInfo.user_data :'',
					log_status: userInfo.log_status|| '',
					flow_type: "compra",
				}}
			return par
		}
		return (
			<CardProduct
				dataLayerbody={params(producto,index)}
				isFreeSamples={false}
				samples={[]}
				noHeight={false}
				noFooter={false}
				slug={'productos'}
				producto={producto}
				key={index}
				isSent={false}
				idProduct={producto.codigoDeProducto}
				likesInfo={
					listLikes !== null &&
					getProductAtr(producto.codigoDeProducto, listLikes)
				}
				setlistLikes={setlistLikes}
				setlocalLikes={setlocalLikes}
				isLocalLiked={isLiked(producto.codigoDeProducto, localLikes)}
				setModalShop={() => {
					setModalShop(producto)
				}}
			/>
		)
	})

	const settings = {
		dots: true,
		infinite: false,
		autoplay: false,
		speed: 1500,
		autoplaySpeed: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		pauseOnHover: true,
		arrows: true,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 550,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	useEffect(() => {
		handleLoadLikes()
		setlocalLikes(
			localStorage.getItem('LOCAL_LIKES_PRODUCTS') !== null
				? JSON.parse(localStorage.getItem('LOCAL_LIKES_PRODUCTS'))
				: []
		)

		// Articles load likes
		handleLoadArticleLikes()
		setlocalArticlesLikes(
			localStorage.getItem('LOCAL_LIKES_ARTICLES') !== null
				? JSON.parse(localStorage.getItem('LOCAL_LIKES_ARTICLES'))
				: []
		)
	}, [])

	return (
		<section className='f-descreet-content'>
			{modalShop && (
				<ModalRetails
					productInfo={modalShop}
					paramRetails={modalShop?.retailsDelProducto}
					hideAction={() => {
						setModalShop(false)
					}}
				/>
			)}
			{isModal && (
				<ModalDetailComment
					setisModal={setisModal}
					handleShowModal={handleShowModal}
					setConsulting={setQuestion}
					consulting={question}
					currentActiveModal={indexModal}
					isMobile={isMobile}
					slug='diana-vahos'
					listComments={listQuestions}
					ratingButtons={false}
					imgAnonimo={IconAnonimo}
					imgSpecialist={DianaVahosMorada}
					className='f-descreet__modal-questions'
				/>
			)}

			<div className='f-descreet__banner-container'>
				<BannerDescreet bannerInfo={pageInfo.bannerPrincipal} />
			</div>

			<div className='f-descreet__welcome-change' id='bienvenido'>
				<h2 className='f-descreet__welcome-change__title'>
					{pageInfo.tituloPrincipal}
				</h2>

				<div className='f-descreet__welcome-change__video-conatiner'>
					{pageInfo?.urlDelVideoPrincipal && (
						<div
							onBlur={() => {
								console.log('BLUR')
							}}
						>
							<iframe
								width='100%'
								height='100%'
								frameBorder='0'
								sandbox='allow-popups allow-same-origin allow-scripts allow-presentation'
								scrolling='no'
								src={
									pageInfo.urlDelVideoPrincipal?.includes('youtube')
										? pageInfo.urlDelVideoPrincipal?.replace(
												'watch?v=',
												'embed/'
										  )
										: pageInfo.urlDelVideoPrincipal?.includes('youtu.be')
										? pageInfo.urlDelVideoPrincipal?.replace(
												'.be/',
												'be.com/embed/'
										  )
										: pageInfo.urlDelVideoPrincipal
								}
								allowFullScreen
								className='f-descreet__welcome-change__video-conatiner__video'
								id='video-change'
							/>
						</div>
					)}

					{pageInfo?.descripcionPrincipal && (
						<RichText
							jsonInfo={pageInfo.descripcionPrincipal.json}
							className='f-descreet__welcome-change__video-conatiner__description'
						/>
					)}
				</div>
			</div>

			<div className='f-descreet__products' id='productos'>
				<RichText
					jsonInfo={pageInfo.informacionProductosDiscreet.json}
					className='f-descreet__products__description'
				/>

				<Slider {...settings} className='f-descreet__products__slider'>
					{listProducts}
				</Slider>
			</div>

			<div className='f-descreet__office'>
				<div className='f-descreet__office__description-container'>
					<RichText
						jsonInfo={pageInfo.informacionConsultorioVirtual.json}
						className='f-descreet__office__description'
					/>

					<a
						href={homeLink + pageInfo.ctaRedireccionConsultorioVirtual}
						className='f-descreet__office__button'
					>
						{pageInfo.ctaConsultorioVirtual}
					</a>
				</div>

				<figure className='f-descreet__office__doctor'>
					<img
						src={pageInfo.imagenConsultorioVirtual?.file.url}
						alt={pageInfo.imagenConsultorioVirtual.description}
					/>
				</figure>
			</div>

			<div className='f-descreet__articles'>
				<h2 className='f-descreet__articles__title'>
					{pageInfo.tituloArticulos}
				</h2>

				<div className='f-descreet__articles__template'>
					{pageInfo.articulos?.map((articulo, index) => {
						return (
							<CardArticleItem
								articulo={articulo}
								key={index}
								isMobile={isMobile}
								idArticle={articulo.contentful_id}
								likesInfo={
									listArticlesLikes !== null &&
									getProductAtr(articulo.contentful_id, listArticlesLikes)
								}
								setlistLikes={setlistArticlesLikes}
								setlocalLikes={setlocalArticlesLikes}
								isLocalLiked={isLiked(
									articulo.contentful_id,
									localArticlesLikes
								)}
							/>
						)
					})}
				</div>

				<a
					href={homeLink + pageInfo.ctaRedireccionArticulos}
					className='f-descreet__office__button f-descreet__articles__button'
				>
					{pageInfo.ctaArticulos}
				</a>

				<span className='f-descreet__articles__separator'></span>

				<div
					className='f-descreet__articles__questions'
					id='preguntasFrecuentes'
				>
					<h2 className='f-descreet__articles__title'>Preguntas frecuentes</h2>

					<div className='f-descreet__articles__questions__template'>
						{infoQuestions.map((quiestion, index) => {
							return (
								<div
									key={index}
									className='f-descreet__articles__questions__template__card'
								>
									<div className='f-descreet__articles__questions__template__card__description'>
										<p>{quiestion.question}</p>
									</div>
									<button
										className='f-descreet__articles__questions__template__card__button'
										onClick={() => handleShowModal(index)}
									>
										Leer más
									</button>
								</div>
							)
						})}
					</div>
				</div>
			</div>

			<div className='f-descreet__leaftlet'>
				<LeafletCard
					isMobile={isMobile}
					leafletContent={leafletContent}
					isDescreet={true}
				/>
			</div>
		</section>
	)
}

export default DescreetContent
