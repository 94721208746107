import React, { useState, useEffect } from 'react'
import imgAnonimoDefault from '../../../assets/images/icono-anonimo.svg'
import { useStaticQuery, graphql } from 'gatsby'
import './modal-detail-comment.scss'

const ModalDetailComment = props => {
	const {
		consulting,
		currentActiveModal,
		likeState,
		titleDoctor,
		setisModal,
		consultationId,
		// localLikes,
		handleLike,
		handleShowModal,
		isMobile,
		slug,
		imgAnonimo,
		ratingButtons = true,
		imgSpecialist,
		className,
	} = props
	console.log(
		'🚀 ~ file: modal-detail-comment.jsx ~ line 122 ~ props.listComments.length',
		props.listComments.length
	)
	console.log(
		'🚀 ~ file: modal-detail-comment.jsx ~ line 25 ~ currentActiveModal',
		currentActiveModal
	)

	const [tempValueLike, settempValueLike] = useState(0)

	useEffect(() => {
		settempValueLike(likeState)
	}, [])

	const closeModal = () => {
		const body = document.getElementsByTagName('body')[0]
		body.classList.remove('overflow-body-hidden')
		setisModal(false)
	}

	const resultQuery = useStaticQuery(
		graphql`
			query IMG_SPECIALIST_DEFAULT {
				allContentfulTenaConsultorios {
					nodes {
						node_locale
						especialistas {
							imagenDeEspecialistaHome {
								file {
									url
									details {
										image {
											height
										}
									}
								}
								description
							}
							slug
						}
					}
				}
			}
		`
	)
	const imgSpecialistDefault = resultQuery.allContentfulTenaConsultorios.nodes
		.filter(e => e.node_locale === process.env.LOCALE)[0]
		.especialistas.filter(e => e.slug === slug)[0].imagenDeEspecialistaHome.file
		.url

	return (
		<section
			className={
				'f-modal-comment-container' + (className ? ' ' + className : '')
			}
		>
			<div className='f-modal-comment-facade' onClick={props.onClose} />
			<div className='f-modal-comment-content'>
				{titleDoctor && (
					<div className='f-modal-comment-title'>
						Pregúntale a {titleDoctor}
					</div>
				)}
				<span
					className='f-modal-comment-closed'
					onClick={() => {
						closeModal()
					}}
				>
					X
				</span>
				<div className='f-modal-comment-img-anon'>
					<div
						className={
							'f-modal-comment-img-anon-container' +
							(imgAnonimo ? ' f-anonimo-prop' : '')
						}
					>
						<img src={imgAnonimo || imgAnonimoDefault} />
					</div>
				</div>
				<div className='f-modal-comment-question'>{consulting.question}</div>
				<div className='f-modal-comment-answer'>{consulting.answer}</div>
				<div className='f-modal-comment-img-specialist'>
					<img
						src={imgSpecialist || imgSpecialistDefault}
						alt='Imagen especialista'
					/>
				</div>
				{ratingButtons && (
					<div className='f-modal-comment-buttons'>
						<div
							className={
								'f-modal-comment-buttons-like' +
								(tempValueLike === 1 ? ' active' : '')
							}
							onClick={() => {
								handleLike(consultationId, 1)
								settempValueLike(1)
							}}
						>
							¡Muy útil!
						</div>

						<div
							className={
								'f-modal-comment-buttons-dislike' +
								(tempValueLike === 2 ? ' active' : '')
							}
							onClick={() => {
								handleLike(consultationId, 2)
								settempValueLike(2)
							}}
						>
							Podría mejorar
						</div>
					</div>
				)}
				{!isMobile && (
					<>
						{props.listComments.length !== currentActiveModal + 1 && (
							<div
								className='f-nav-modal-button f-next-button'
								onClick={() => handleShowModal(currentActiveModal + 1)}
							>
								<span className='icon-left f-icon'></span>
							</div>
						)}
						{currentActiveModal !== 0 && (
							<div
								className='f-nav-modal-button f-back-button'
								onClick={() => handleShowModal(currentActiveModal - 1)}
							>
								<span className='icon-left f-icon'></span>
							</div>
						)}
					</>
				)}
			</div>
		</section>
	)
}

export default ModalDetailComment
