import React from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import useMobileMode from '../../../shared/hooks/useMobileMode'
import './banner-descreet.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const BannerDescreet = props => {
	
	const { bannerInfo } = props
	const { isMobile } = useMobileMode()
  
	const settings = {
		dots: true,
		infinite: true,
		autoplay: true,
		speed: 1500,
		autoplaySpeed: 10000,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: true,
		arrows: false,
	}

	const listItems =
		bannerInfo &&
		bannerInfo.slice(0, 5).map((slide, key) => {
			return (
				<div
					key={key}
					onClick={() => {
						if (slide.enlace && slide.enlace !== null) {
							window.location.href = slide.enlace
						}
					}}
				>
					<div className='f-slider-pos'>
						<Img
							className='f-slider-image'
							fluid={
								isMobile && slide.bannerMobile
									? slide.bannerMobile.fluid
									: slide.bannerDesktop.fluid
							}
							alt={slide.titulo}
						/>
						{slide.tituloBanner && (
							<p className='f-slider-title'>{slide.tituloBanner}</p>
						)}
						{slide.botonBanner && (
							<button
								className={'f-primary-purple f-slider-button'}
								onClick={() => {
									window.location.href = slide.cta
								}}
							>
								{convertText(slide.botonBanner)}
							</button>
						)}
					</div>
				</div>
			)
		})

	return (
		<>
			<Slider {...settings} className='f-slider-conatiner'>
				{listItems}
			</Slider>
		</>
	)
}

export default BannerDescreet
